import { createIcon } from '@chakra-ui/react';
import colors from 'src/theme/colors';

export default createIcon({
  displayName: 'WarningTriangleIcon',
  viewBox: '0 0 18 17',
  path: [
    <path
      key="outertriangle"
      d="M2.61803 16.5H15.382C16.497 16.5 17.2223 15.3265 16.7236 14.3292L10.3416 1.56525C9.78885 0.459673 8.21114 0.459676 7.65836 1.56525L1.27639 14.3292C0.777717 15.3265 1.50296 16.5 2.61803 16.5Z"
      fill="none"
      stroke={colors.podcastRed600}
    />,
    <path
      key="inner"
      d="M9.9566 5H8.0434C7.75088 5 7.52083 5.25001 7.54513 5.54152L7.96179 10.5415C7.98339 10.8007 8.20002 11 8.46007 11H9.53993C9.79998 11 10.0166 10.8007 10.0382 10.5415L10.4549 5.54152C10.4792 5.25001 10.2491 5 9.9566 5Z"
      fill={colors.podcastRed600}
    />,
    <circle key="circle" cx="9" cy="13" r="1" fill={colors.podcastRed600} />,
  ],
});
