import { Icon, IconProps } from '@chakra-ui/react';
import colors from 'src/theme/colors';

export default function InfoIcon({
  colour,
  ...props
}: Omit<IconProps, 'css'> & { colour?: string }) {
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" {...props}>
      <rect width="14" height="14" rx="7" fill={colour || colors.podcastShade400} />
      <rect x="6" y="3" width="2" height="2" rx="1" fill="white" />
      <rect x="6" y="6" width="2" height="5" rx="1" fill="white" />
    </Icon>
  );
}
