import { ButtonProps, Icon, IconProps } from '@chakra-ui/react';
import colors from 'src/theme/colors';

interface RightChevronProps extends Omit<IconProps, 'css'> {
  buttonProps?: ButtonProps;
}

export default function RightChevron({ buttonProps, ...props }: RightChevronProps) {
  return (
    <Icon
      width="6px"
      height="10px"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2308_3049)">
        <path
          opacity={buttonProps?.variant === 'tertiary' ? '1' : '0.8'}
          d="M1 1L5 5L1 9"
          stroke={buttonProps?.variant === 'tertiary' ? colors.podcastShade400 : 'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2308_3049">
          <rect width="6" height="10" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
