import { Icon, IconProps } from '@chakra-ui/react';

export default function QuestionMark(props: Omit<IconProps, 'css'>) {
  return (
    <Icon
      width="15px"
      height="15px"
      fill="none"
      color="AlituPurple"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        d="M8.42143 12.5196C8.39464 13.4732 7.6875 14.0625 6.77143 14.0357C5.89286 14.0089 5.20179 13.3821 5.22857 12.4232C5.25536 11.4696 5.98393 10.8643 6.8625 10.8911C7.78393 10.9179 8.45357 11.5661 8.42143 12.5196ZM11.0304 5.7C10.8054 6.01607 10.3018 6.42321 9.66964 6.91607L8.96786 7.39821C8.625 7.66071 8.41071 7.97143 8.29821 8.325C8.23929 8.5125 8.19643 9 8.18571 9.32679C8.18036 9.39107 8.14286 9.53572 7.94464 9.53572C7.74643 9.53572 6.06964 9.53572 5.85 9.53572C5.63036 9.53572 5.60357 9.40714 5.60893 9.34286C5.64107 8.45357 5.76964 7.71964 6.13929 7.13036C6.6375 6.3375 8.04107 5.50179 8.04107 5.50179C8.25536 5.34107 8.42143 5.16964 8.55 4.98214C8.78571 4.66071 8.97857 4.30179 8.97857 3.91607C8.97857 3.47143 8.87143 3.04821 8.5875 2.69464C8.25536 2.28214 7.89643 2.08393 7.20536 2.08393C6.525 2.08393 6.12857 2.42679 5.84464 2.87679C5.56072 3.32679 5.60893 3.85714 5.60893 4.33929H3C3 2.51786 3.47679 1.35536 4.48393 0.669643C5.16429 0.203571 6.03214 0 7.04464 0C8.37321 0 9.42857 0.246429 10.3607 0.953572C11.2232 1.60714 11.6786 2.52857 11.6786 3.77143C11.6786 4.5375 11.4107 5.18036 11.0304 5.7Z"
        fill="currentColor"
      />
    </Icon>
  );
}
