import { Icon } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';
import colors from 'src/theme/colors';
import { ChakraIconProps } from 'types';

interface TickIconFilledProps extends ChakraIconProps {
  colour?: string;
}

export default function TickIconFilled({ colour, ...props }: TickIconFilledProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        fill="url(#paint0_linear_1456_23141)"
        stroke={colours.white}
        strokeWidth="2"
      />
      <path
        d="M8 12L10.6667 15L16 9"
        stroke={colours.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1456_23141"
          x1="12"
          y1="2"
          x2="12"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colour || colors.podcastPurple} />
          <stop offset="1" stopColor={colour || colors.podcastPurple800} />
        </linearGradient>
      </defs>
    </Icon>
  );
}
