import { Icon } from '@chakra-ui/react';
import colors from 'src/theme/colors';

export function LeftArrow({ colour }: { colour?: string }) {
  return (
    <Icon
      width="13px"
      height="12px"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/Icon"
    >
      <path
        d="M6 1 1 6m0 0 5 5M1 6h11"
        stroke={colour || colors.podcastShade500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
