import { Icon, IconProps } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';

export default function RightArrow(props: Omit<IconProps, 'css'>) {
  return (
    <Icon
      width="15px"
      height="10px"
      viewBox="0 0 15 10"
      fill="none"
      color={colours.white}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity=".8"
        d="m9.5 1 4 4m0 0-4 4m4-4h-12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
