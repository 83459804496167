export default function DefaultArtworkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" fill="none">
      <path
        d="M12 0.285645C5.37321 0.285645 0 5.65886 0 12.2856C0 17.226 2.98768 21.4678 7.25357 23.3053C7.06714 22.1937 6.92304 21.0773 6.87868 20.1901C4.29 18.5053 2.57143 15.5964 2.57143 12.2856C2.57143 7.08654 6.80089 2.85707 12 2.85707C17.1991 2.85707 21.4286 7.08654 21.4286 12.2856C21.4286 15.5958 19.71 18.5053 17.122 20.1928C17.0773 21.0767 16.9323 22.1948 16.7451 23.309C21.0107 21.4678 24 17.2249 24 12.2856C24 5.65886 18.6268 0.285645 12 0.285645ZM12 16.9999C10.2386 16.9999 8.57143 17.462 8.57143 19.3437C8.57143 21.1185 9.26143 24.9312 9.67607 26.458C9.95357 27.4785 10.9875 27.7142 12 27.7142C13.0125 27.7142 14.0491 27.4798 14.3239 26.4617C14.7375 24.9231 15.4286 21.1142 15.4286 19.3464C15.4286 17.4606 13.7625 16.9999 12 16.9999ZM12 15.2856C13.658 15.2856 15 13.9437 15 12.2856C15 10.6276 13.6125 9.28564 12 9.28564C10.3875 9.28564 9 10.6249 9 12.2856C9 13.9464 10.3393 15.2856 12 15.2856ZM19.7143 12.2856C19.7143 8.02511 16.2605 4.57136 12 4.57136C7.73946 4.57136 4.28571 8.02672 4.28571 12.2856C4.28571 14.6873 5.40643 16.806 7.12714 18.2214C7.38734 17.5437 7.88411 16.8349 8.84411 16.3442C8.84464 16.341 8.86071 16.341 8.87143 16.341C7.65536 15.3981 6.85714 13.941 6.85714 12.2856C6.85714 9.44529 9.15964 7.14279 12 7.14279C14.8404 7.14279 17.1429 9.44529 17.1429 12.2856C17.1429 13.9421 16.3463 15.3998 15.1302 16.3399C15.1372 16.3413 15.1574 16.3425 15.1556 16.3439C16.1161 16.8346 16.6122 17.5428 16.8726 18.221C18.5946 16.8071 19.7143 14.6856 19.7143 12.2856Z"
        fill="#D6FFF2"
      />
    </svg>
  );
}
