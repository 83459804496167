import { CSSProperties } from 'react';
import colors from 'src/theme/colors';

interface PlusProps {
  width?: string;
  height?: string;
  fill?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

function Plus({
  width = '17',
  height = '17',
  onClick = () => null,
  fill = colors.podcastShade400,
  style = {},
}: PlusProps) {
  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      viewBox="-1 0 18 16"
      onClick={onClick}
      style={style}
    >
      <path
        d="M 15.132,6.882 H 9.116 V 0.868 C 9.116,0.594 8.744,0 8,0 7.256,0 6.884,0.594 6.884,0.868 V 6.884 H 0.868 C 0.594,6.884 0,7.256 0,8 0,8.744 0.594,9.116 0.868,9.116 h 6.016 v 6.016 C 6.884,15.406 7.256,16 8,16 8.744,16 9.116,15.406 9.116,15.132 V 9.115 h 6.016 C 15.406,9.115 16,8.743 16,7.999 16,7.255 15.406,6.883 15.132,6.883 Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Plus;
