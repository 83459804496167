import { Icon } from '@chakra-ui/react';
import colors from 'src/theme/colors';
import { ChakraIconProps } from 'types';

export default function TrashIcon({
  colour,
  width = '18px',
  height = '18px',
  ...props
}: { colour?: string } & ChakraIconProps) {
  return (
    <Icon
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 1H12"
        stroke={colour || colors.podcastShade500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5H15M3 5L4 17H14L15 5M3 5H1M15 5H17"
        stroke={colour || colors.podcastShade500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
