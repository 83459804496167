import { Icon, IconProps } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';
import colors from 'src/theme/colors';

/**
 * @description This icon is used to distinguish between publish states with external hosts and Alitu.
 */
export default function AlituTickIcon(props: Omit<IconProps, 'css'>) {
  return (
    <Icon
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0Z" fill={colors.podcastPurple} />
      <path
        d="m6 9 2.5 3L12 6"
        stroke={colours.white}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
