import { Icon, IconProps } from '@chakra-ui/react';

export default function Question(props: Omit<IconProps, 'css'>) {
  return (
    <Icon
      width="24px"
      height="24px"
      fill="none"
      color="podcastShade400"
      xmlns="http://www.w3.org/2000/svg"
      marginLeft="16px"
      {...props}
    >
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm1.082 17.502c-.027.953-.734 1.543-1.65 1.516-.878-.027-1.57-.654-1.543-1.613.027-.953.756-1.559 1.634-1.532.922.027 1.591.675 1.56 1.629Zm2.61-6.82c-.226.316-.73.723-1.362 1.216l-.701.482a1.858 1.858 0 0 0-.67.927c-.059.188-.102.675-.113 1.002-.005.064-.042.209-.24.209H10.51c-.22 0-.247-.129-.241-.193.032-.89.16-1.623.53-2.212.498-.793 1.902-1.63 1.902-1.63.214-.16.38-.331.509-.519.235-.321.428-.68.428-1.066 0-.444-.107-.868-.39-1.221-.333-.413-.692-.61-1.383-.61-.68 0-1.077.342-1.36.792-.285.45-.236.98-.236 1.462H7.66c0-1.821.477-2.983 1.485-3.67.68-.465 1.548-.669 2.56-.669 1.329 0 2.384.247 3.316.954.863.653 1.318 1.575 1.318 2.818a3.22 3.22 0 0 1-.648 1.928Z"
        fill="currentColor"
      />
    </Icon>
  );
}
